import { type ReactNode, useRef, useEffect } from 'react';
import { motion, useInView, useReducedMotion } from 'framer-motion';
import { isHeroButtonInView } from '../../homepageStore';
import { TextLoop } from '../TextLoop/TextLoop';
import styles from './DefaultHomeHero.module.css';

export const dynamicText = [
  'ready-to-eat meals',
  'simple recipes',
  'shakes + smoothies',
  'supplements',
  'fruit + veggies',
  'premium meats',
  'kids’ snacks',
  'baked goods',
  'soups + stews',
  'salads + bowls',
  'pizza + pasta',
];

export type HomeHeroProps = {
  variantSources: {
    srcSet: string;
    media: string;
    width: number;
    height: number;
  }[];
  variantImage: {
    srcSet: string;
    src: string;
    height: number;
    width: number;
  };
  defaultSources: {
    srcSet: string;
    media: string;
  }[];
  defaultImage: {
    srcSet: string;
    src: string;
    height: number;
    width: number;
  };
  children: ReactNode;
  resumeQuizButton: ReactNode;
};

export function DefaultHomeHero(props: HomeHeroProps) {
  const {
    defaultSources,
    defaultImage: {
      srcSet: imageSrcSet,
      src: imageSrc,
      width: imageWidth,
      height: imageHeight,
    },
    children,
    resumeQuizButton,
  } = props;

  const shouldReduceMotion = useReducedMotion();

  const buttonRef = useRef(null);
  const isButtonInView = useInView(buttonRef);

  useEffect(() => {
    // Update store
    isHeroButtonInView.set(isButtonInView);
  }, [isButtonInView]);

  return (
    <div className={styles.container}>
      {children}
      <motion.picture
        initial={
          shouldReduceMotion
            ? { opacity: 0, scale: 1 }
            : { opacity: 0, scale: 1.02 }
        }
        animate={shouldReduceMotion ? { opacity: 1 } : { opacity: 1, scale: 1 }}
        transition={{ duration: 0.45 }}
      >
        {defaultSources.map(({ media, srcSet }) => {
          return <source key={media} srcSet={srcSet} media={media} />;
        })}
        <img
          srcSet={imageSrcSet}
          decoding="async"
          height={imageHeight}
          width={imageWidth}
          className={styles.image}
          src={imageSrc}
          alt="Hungryroot Products"
        />
      </motion.picture>
      <motion.div
        className={styles['container-inner']}
        initial={shouldReduceMotion ? { opacity: 0 } : { opacity: 0, y: '8px' }}
        animate={shouldReduceMotion ? { opacity: 1 } : { opacity: 1, y: 0 }}
        transition={{ delay: 0.4, duration: 0.45 }}
      >
        <h1 className={styles.heading}>The easiest way to eat healthy</h1>

        <div className={styles.subheading}>
          <TextLoop
            staticLabel="The best"
            dynamicContent={dynamicText}
            contentClassName={styles.demi}
          />
          <span className={styles['subheading-static']}>
            <span className={styles['subheading-static-inner']}>
              for you and your family.
            </span>
          </span>
        </div>
        <span ref={buttonRef}>{resumeQuizButton}</span>
      </motion.div>
    </div>
  );
}
