import { useEffect } from 'react';
import { useIdentify } from '@hungryroot/customer';
import { GoogleAnalytics } from '../GoogleAnalytics/GoogleAnalytics';
import { islandRoot } from '../IslandRoot/IslandRoot';
import { Segment } from '../Segment/Segment';

export const BootstrapAnalyticsIsland = islandRoot(BootstrapAnalytics);

declare global {
  interface Window {
    dataLayer: unknown[];
  }
}

export function BootstrapAnalytics() {
  const { customerData } = useIdentify();

  useEffect(() => {
    if (customerData === undefined) {
      return;
    }

    window.dataLayer.push({
      customer: {
        plan: {
          id: customerData.plan,
        },
        email: customerData.email,
        id: customerData.id,
      },
    });
  }, [customerData]);

  return (
    <>
      <Segment />
      <GoogleAnalytics />
    </>
  );
}
