import { useStore } from '@nanostores/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useMedia } from 'react-use';
import { BreakpointQueryLarge } from '@hungryroot/tokens';
import { isHeroButtonInView } from '../../homepageStore';
import { useGetFormattedPromo, useGetPromoData } from '../../hooks';
import { CtaButton } from '../CtaButton/CtaButton';
import { HomeHeader } from '../HomeHeader/HomeHeader';
import styles from './PromoMessage.module.css';

export function PromoMessage() {
  const { data: promoData } = useGetPromoData();
  const promoText = useGetFormattedPromo(styles.bold, promoData, styles.promo);

  const isDesktop = useMedia(BreakpointQueryLarge, false);
  const $isHeroButtonInView = useStore(isHeroButtonInView);

  return (
    <>
      <AnimatePresence>
        {isDesktop && !$isHeroButtonInView && (
          <motion.div
            initial={{ opacity: 0, top: '-100px' }}
            animate={{ opacity: 1, top: 0 }}
            exit={{ opacity: 0, top: '-100px' }}
            transition={{ duration: 0.45 }}
            className={styles.fixed}
          >
            <HomeHeader
              actionContainerClassName={styles['nav-links-promo']}
              action={promoText}
            />
          </motion.div>
        )}
      </AnimatePresence>
      {promoText && (
        <CtaButton
          className={styles.button}
          isVisible={!isDesktop && !$isHeroButtonInView}
          text={promoText}
        />
      )}
    </>
  );
}
