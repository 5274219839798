import { useExperiment } from '@hungryroot/experiments';

type ImageDefinition = {
  src: string;
  height: number;
  width: number;
};

type ResponsiveImageDefinition = {
  mobile: ImageDefinition;
  desktop: ImageDefinition;
};

export type HowItWorksProps = {
  tellUsAboutYourselfImage: ResponsiveImageDefinition;
  getYourPersonalizenPlanImage: ResponsiveImageDefinition;
  enjoyHealthyDeliveriesImage: ResponsiveImageDefinition;
  getPersonalRecommendationsImage: ResponsiveImageDefinition;
  yourPersonalizedPlanImage: ResponsiveImageDefinition;
  healthyDeliveriesImage: ResponsiveImageDefinition;
};

export type CardContent = {
  id: string;
  title: string;
  image: ResponsiveImageDefinition;
  copy: string;
};

export function useHowItWorksExperimentApi(
  props: HowItWorksProps,
  defaultContent: {
    heading: string;
    subheading?: string;
    cardContent: CardContent[];
  }
) {
  const variant = useExperiment(157);

  const {
    getPersonalRecommendationsImage,
    yourPersonalizedPlanImage,
    healthyDeliveriesImage,
  } = props;

  if (variant === undefined) {
    return;
  }

  if (variant < 1) {
    return { ...defaultContent, variant };
  }

  return {
    heading: 'Meet SmartCart™',
    subheading:
      'A first-of-its-kind grocery technology that helps you save time and eat healthy.',
    cardContent: [
      {
        id: 'tell',
        title: 'Get personal recommendations',
        image: getPersonalRecommendationsImage,
        copy: `SmartCart™ uses your preferences, feedback, and ordering behavior to fill your cart with food you’ll love.`,
      },
      {
        id: 'personalized',
        title: 'Customize your delivery',
        image: yourPersonalizedPlanImage,
        copy: `Take our suggestions or edit your cart to get exactly what you want each week.`,
      },
      {
        id: 'healthy',
        title: 'See SmartCart™ get smarter',
        image: healthyDeliveriesImage,
        copy: `Save time and stress as SmartCart™ gets to know you better and predicts your perfect cart for each delivery.`,
      },
    ],
    variant,
  };
}
