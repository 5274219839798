import { useState, useEffect } from 'react';
import { AnimatePresence, motion, useReducedMotion } from 'framer-motion';
import styles from './TextLoop.module.css';

interface Props {
  staticLabel?: string;
  dynamicContent: string[];
  containerClassName?: string;
  contentClassName?: string;
}

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

export function TextLoop(props: Props) {
  const { dynamicContent, containerClassName, contentClassName, staticLabel } =
    props;
  const [index, setIndex] = useState(0);

  const shouldReduceMotion = useReducedMotion();

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        let next = index + 1;
        if (next === dynamicContent.length) {
          next = 0;
        }
        setIndex(next);
      },
      (shouldReduceMotion ? 3 : 2) * 1000
    );

    return () => {
      clearTimeout(timeout);
    };
  }, [index, setIndex]);

  return (
    <>
      <span className={styles.hidden}>{dynamicContent[index]}</span>
      <AnimatePresence>
        <motion.span
          className={containerClassName}
          style={{ position: 'absolute', width: '400px' }}
          key={`${index}-${getRandomInt(100)}`}
          initial={
            shouldReduceMotion ? { opacity: 0 } : { y: '100%', opacity: 0 }
          }
          animate={shouldReduceMotion ? { opacity: 1 } : { y: 0, opacity: 1 }}
          exit={
            shouldReduceMotion ? { opacity: 0 } : { y: '-32px', opacity: 0 }
          }
          transition={
            shouldReduceMotion ? { duration: 0.25 } : { duration: 0.45 }
          }
        >
          {staticLabel}{' '}
          <span className={contentClassName}>{dynamicContent[index]}</span>
        </motion.span>
      </AnimatePresence>
    </>
  );
}
