import { type ComponentProps } from 'react';
import { useExperiment } from '@hungryroot/experiments';
import { DefaultHomeHero, type HomeHeroProps } from './DefaultHomeHero';
import { HomeHeroContainer } from './Exp_157/HomeHeroContainer/HomeHeroContainer';
import { StaticHomeHero } from './Exp_157/StaticHomeHero/StaticHomeHero';
import styles from './DefaultHomeHero.module.css';

type StaticHomeHeroProps = ComponentProps<typeof StaticHomeHero>;

export const homeHeroCta = styles.button;

const HEADING_COPY = 'The easiest way to eat healthy';

export function HomeHero(props: HomeHeroProps) {
  const variant = useExperiment(157);

  const staticHeroProps: StaticHomeHeroProps = {
    ...props,
    heading: HEADING_COPY,
  };

  return (
    <HomeHeroContainer showPlaceholder={variant === undefined}>
      {(variant === undefined || variant < 1) && <DefaultHomeHero {...props} />}
      {(variant === undefined || variant > 0) && (
        <StaticHomeHero {...staticHeroProps} />
      )}
    </HomeHeroContainer>
  );
}
